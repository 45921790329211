<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="市政道路名称" prop="roadName" style="margin-bottom: 0px">
                <el-input v-model="form.roadName" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  @change="handleBidChage"
                  filterable
                ></el-cascader>
              </el-form-item>
              <el-form-item label="道路类别" prop="roadType">
                <el-select v-model="form.roadType" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.roadType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="城中村">
                <el-select v-model="form.villageId" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in municipalRoadData.data"
                    :key="idx"
                    :label="item.villageName"
                    :value="item.villageId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="主/次干道">
                <el-select v-model="form.roadLevel" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.roadLevel.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="保洁等级">
                <el-select v-model="form.cleanLevel" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.cleanLevel.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="是否进行机扫">
                <el-checkbox v-model="form.isSweeper">是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否进行清洗">
                <el-checkbox v-model="form.isWasher">是</el-checkbox>
              </el-form-item>
              <el-form-item label="起止位置" class="startStopPosition">
                <el-input v-model="form.startStopPosition" placeholder="请输入" maxlength="256"></el-input>
              </el-form-item>
              <el-form-item label="是否5333严管区道路">
                <el-checkbox v-model="form.isDistrictZone">是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否商业街">
                <el-checkbox v-model="form.isBusinessStreet">是</el-checkbox>
              </el-form-item>
              <el-form-item label="主车道长度(m)">
                <el-input v-model="form.mainRoadLen" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="辅道长度(m)">
                <el-input v-model="form.sideRoadLen" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="主车道宽度(m)">
                <el-input v-model="form.mainRoadWide" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="辅道宽度(m)">
                <el-input v-model="form.sideRoadWide" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="主车道面积(m²)">
                <el-input v-model="form.mainRoadArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="辅道面积(m²)">
                <el-input v-model="form.sideRoadArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="人行道长度(m)">
                <el-input v-model="form.sideWalkLen" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="绿化带长度(m)">
                <el-input v-model="form.greenBeltsLen" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="人行道宽度(m)">
                <el-input v-model="form.sideWalkWide" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="绿化道宽度(m)">
                <el-input v-model="form.greenBeltsWide" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="人行道面积(m²)">
                <el-input v-model="form.sideWalkArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="绿化带面积(m²)">
                <el-input v-model="form.greenBeltsArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="门店门前面积(m²)">
                <el-input v-model="form.storeFrontArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="各面积小计(m²)">
                <el-input v-model="form.areaTotal" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="绿化带数">
                <el-input v-model="form.greenBeltsNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="护栏数(个)">
                <el-input v-model="form.guardrailNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="路段配备打扫人员">
                <el-input v-model="form.roadSweepNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="路段配备保洁人员">
                <el-input v-model="form.roadCleanNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="蓝色果皮箱(个)">
                <el-input v-model="form.blueFruitTrunk" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="240L密闭式垃圾桶(个)">
                <el-input v-model="form.closedBin240l" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="果皮咖啡色(个)">
                <el-input v-model="form.brownFruitTrunk" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="660L密闭式垃圾桶(个)">
                <el-input v-model="form.closedBin660l" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="工具房(个)">
                <el-input v-model="form.toolRoom" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="垃圾房(个)">
                <el-input v-model="form.garbageRoom" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="路段配备人员总数(个)">
                <el-input v-model="form.roadDemandNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="车道数量(条)">
                <el-input v-model="form.laneNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>

              <!-- 垃圾压缩箱 -->
              <el-form-item label="特殊道路管理"></el-form-item>
              <div class="add-item" v-for="(tab,index) in form.cityRoadSpecialList" :key="index">
                <div style="text-align:right;min-height:20px">
                  <span
                    v-if="index!=0"
                    style="display:inline-block;padding: 10px 15px;width:40px;cursor:pointer"
                    @click="onDeleteTab(tab)"
                  >
                    <i class="el-icon-close"></i>
                  </span>
                </div>
                <div class="add-item-main">
                  <el-form-item
                    label="道路名称"
                    :prop="'cityRoadSpecialList.'+index+'.specialRoadName'"
                    :rules="cityRoadSpecialListRules.specialRoadName"
                  >
                    <el-input v-model="tab.specialRoadName" placeholder="请输入" maxlength="20"></el-input>
                  </el-form-item>
                  <el-form-item label="道路类型">
                    <el-select v-model="tab.specialRoadType" placeholder="请选择" filterable>
                      <el-option
                        v-for="(item, idx) in $enums_hw.specialRoadType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="面积(m²)">
                    <el-input v-model="tab.area" placeholder="请输入" maxlength="7"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="划线"></el-form-item> -->
                </div>
              </div>
              <div class="add-btn">
                <el-button class="btn" style="margin-left: 20px" @click="onAddTab">+ 新增</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <ayl-map-polygon v-model="mapData"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      debug:0,
      nav: [{ name: "编辑市政道路" }],
      //地图数据
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如           [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      submitLoading: false,
      bidList: [],
      municipalRoadData: [], //城中村
      form: {},
      rules: {
        roadName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位市政道路名称`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        },
        roadType: {
          required: true,
          message: "请选择道路类别",
          trigger: "change"
        }
      },
      cityRoadSpecialListRules: {
        specialRoadName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位道路名称`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ]
      }
    };
  },
  methods: {
    async handleBidChage(value) {
      this.form.bidId = value[1]; //所属标段
      this.form.villageId = null;
      this.municipalRoadData = [];
      //获取城中村列表
      this.municipalRoadData = await this.$api_hw.urbanVillagesManagement_listVillage({
        bidId: value[1]
      });

      let self = this;
      self.form.bidId = value[1]; //所属标段
      if (self.mapData && self.form.bidId !== "") {
        self.mapData.move2center = true;
        self.$api_hw
          .common_getBidInfo({
            id: self.form.bidId
          })
          .then(res => {
            self.log("mk", res.bidCoordinateScope);
            self.mapData.scope = Array.isArray(res.bidCoordinateScope)?res.bidCoordinateScope:JSON.parse(res.bidCoordinateScope);
          })
          .catch(() => {
            self.mapData.scope = null;
          });
      }
    },

    //异步提交表单
    async submit() {
      let self = this;
      await self.$api_hw.municipalRoad_update({
        sanitationCityRoadVO: _fixData(self.form)
      });

      

      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "isSweeper":
            case "isWasher":
            case "isDistrictZone":
            case "isBusinessStreet":
              d[key] = d[key] * 1;
              break;
            case "roadCoordinateScope":
              if (self.mapData.polygonScope) {
                d[key] = JSON.stringify(self.mapData.polygonScope);
                d.isDrawn = 1;
              }else{
                d[key] ="";
                d.isDrawn = 0;
              }
              break;
          }
        }
        return d;
      }
    },

    async onSubmit() {
      var self = this;

      if (self.submitLoading) return; //防止重复提交

      self.$refs.form.validate(async valid => {
        if (!valid) return;
        self.submitLoading = true;
        try {
          await self.submit();
          self.$notify({
            title: "成功",
            message: `${self.nav[0].name}成功`,
            type: "success"
          });

          BUS.$emit(
            BUSEVENT.REFRESH_MUNICIPAL_ROAD_MANAGEMENT,
            self.$route.query.id
          );
          self.onClose();
          self.$router.push(
            "/district-management/municipal-road/list"
          );
        } catch (e) {
          // console.error(e);
        } finally {
          self.submitLoading = false;
        }
      });
    },
    onClose() {
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    },
    onMapClear() {},
    onMapSearch() {},
    onAddTab() {
      this.form.cityRoadSpecialList.push({
        tabsId: null,
        roadName: null, //道路名称
        roadCategory: null, //道路类别
        area: null //面积
      });
    },
    onDeleteTab(item) {
      let list = this.form.cityRoadSpecialList;
      let indexTab = list.indexOf(item);
      //pm要求至少要留1个tab

      if (indexTab !== -1 && list.length > 1) {
        list.splice(indexTab, 1);
      }
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes() {
      return this.$store.state.permission.routes;
    }
  },
  async mounted() {
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
  },
  async activated() {
    let self = this;
    //详情数据
    let formData = await self.$api_hw.municipalRoad_getById({
      id: self.$route.query.id
    });

    //获取城中村列表
    self.municipalRoadData = await this.$api_hw.urbanVillagesManagement_listVillage({
      bidId: formData.bidId
    });

    self.form = _fixData(formData);

    /**
     * 把服务器端的Data 修改一下以方便编辑
     */
    function _fixData(d) {
      for (let key in d) {
        switch (key) {
          case "isSweeper":
          case "isWasher":
          case "isDistrictZone":
          case "isBusinessStreet":
            d[key] = !!d[key];
            break;
          case "roadCoordinateScope":
            try {
              self.mapData.polygonScope = JSON.parse(d[key]);
            } catch (e) {
              self.mapData.polygonScope = null;
            }
            break;
          case "bidScope":
              try {
                self.mapData.move2center = true;
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
        }
      }
      return d;
    }
  },
  deactivated() {}
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  margin: 20px 0
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
<style>
.startStopPosition {
  width: 100% !important;
}
.startStopPosition .el-input__inner {
  width: 320px;
}
</style>